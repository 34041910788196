import { SelfServiceLoginFlow, SubmitSelfServiceLoginFlowBody } from '@ory/kratos-client';
import { CardTitle } from '@ory/themes';
import { AxiosError } from 'axios';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { handleGetFlowError, handleFlowError } from '../lib/errors';
import { useLogoutHandler } from '../lib/hooks';
import ory from '../lib/ory';
import { Flow } from '../lib/ui';

import marchLogoMedium from '../assets/March-Baseline-Logo.svg';
import { NextPageWithLayout } from '../lib/layout';

const Login: NextPageWithLayout = () => {
  const [flow, setFlow] = useState<SelfServiceLoginFlow>();

  // Get ?flow=... from the URL
  const router = useRouter();
  const {
    return_to: returnTo,
    flow: flowId,
    // Refresh means we want to refresh the session. This is needed, for example, when we want to update the password
    // of a user.
    refresh,
    // AAL = Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
    // to perform two-factor authentication/verification.
    aal,
  } = router.query;

  // This might be confusing, but we want to show the user an option
  // to sign out if they are performing two-factor authentication!
  const onLogout = useLogoutHandler([aal, refresh]);

  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (!router.isReady || flow) {
      return;
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getSelfServiceLoginFlow(String(flowId))
        .then(({ data }) => {
          setFlow(data);
        })
        .catch(handleGetFlowError(router, 'login', setFlow));
      return;
    }

    // Otherwise we initialize it
    ory
      .initializeSelfServiceLoginFlowForBrowsers(
        Boolean(refresh),
        aal ? String(aal) : undefined,
        returnTo ? String(returnTo) : undefined,
      )
      .then(({ data }) => {
        setFlow(data);
      })
      .catch(handleFlowError(router, 'login', setFlow, returnTo ? String(returnTo) : undefined));
  }, [flowId, router, router.isReady, aal, refresh, returnTo, flow]);

  const onSubmit = (values: SubmitSelfServiceLoginFlowBody) =>
    router
      // On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
      // his data when she/he reloads the page.
      .push(`/login?flow=${flow?.id}`, undefined, { shallow: true })
      .then(() =>
        ory
          .submitSelfServiceLoginFlow(String(flow?.id), undefined, values)
          // We logged in successfully! Let's bring the user home.
          .then(() => {
            if (flow?.return_to) {
              window.location.href = flow?.return_to;
              return;
            }
            router.push('/');
          })
          .then(() => {})
          .catch(handleFlowError(router, 'login', setFlow))
          .catch((err: AxiosError) => {
            console.log('unhandled ory error', err);
            // If the previous handler did not catch the error it's most likely a form validation error
            if (err.response?.status === 400) {
              // Yup, it is!
              setFlow(err.response?.data);
              return;
            }

            return Promise.reject(err);
          }),
      );

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex flex-col w-full max-w-sm items-center">
        {/* <h2 className="text-secondary text-lg text-center mb-2"> */}
          {/* <span className="whitespace-nowrap">March Matching Engine</span> */}
        {/* </h2> */}

        <div className="w-full max-w-sm px-4">
          <Image src={marchLogoMedium} alt="march" layout="responsive" />
        </div>

        <div className="mb-4 w-full max-w-xs">
          <CardTitle>
            {(() => {
              if (flow?.refresh) {
                return 'Confirm Action';
              } else if (flow?.requested_aal === 'aal2') {
                return 'Two-Factor Authentication';
              }
              // return 'Sign In';
            })()}
          </CardTitle>
          <Flow onSubmit={onSubmit} flow={flow} />
        </div>
        {aal || refresh ? (
          <div className="border mb-4">
            <a data-testid="logout-link" onClick={onLogout}>
              Log out
            </a>
          </div>
        ) : (
          <>
            {/* <div className="border mb-4">
            <Link href="/registration" passHref>
             Create account
            </Link>
          </div> */}
            {/* <div className="border mb-4">
            <Link href="/recovery" passHref>
             Recover your account
            </Link>
          </div> */}
          </>
        )}
      </div>
    </div>
  );
};

Login.getLayout = (page) => page;

export default Login;
